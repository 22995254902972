import { amountFromCents, sum, toCents } from "../../utils/Numbers.util";
import { Invoice, InvoiceItem } from "./Invoice.types";

const recalculateItem = (item: InvoiceItem): InvoiceItem => {
    const priceInCents = toCents(item.price || 0);
    const taxRate = parseFloat(String(item.taxRate || 0));
    const newAmountNetInCents = priceInCents * parseFloat(item.quantity || '0');
    const newTaxAmountInCents = taxRate > 0 ? taxRate * newAmountNetInCents / 100 : 0;
    const newAmountGrossInCents = sum(newAmountNetInCents, newTaxAmountInCents);
    return {
        ...item,
        price: amountFromCents(priceInCents),
        amountNet: amountFromCents(newAmountNetInCents),
        taxAmount: amountFromCents(newTaxAmountInCents),
        taxRate: String(taxRate),
        amountGross: amountFromCents(newAmountGrossInCents),
    }
}


export const recalculateInvoice = (invoice: Invoice): Invoice => {
    invoice.items.reduce<number>((acc, item) => {
        return sum(acc, item.amountGross)
    }, 0)
    return {
        ...invoice,
        totalTax: (invoice.items.reduce<number>((acc, item) => sum(acc, item.taxAmount), 0)).toFixed(2),
        totalGross: (invoice.items.reduce<number>((acc, item) => sum(acc, item.amountGross), 0)).toFixed(2),
        totalNet: (invoice.items.reduce<number>((acc, item) => sum(acc, item.amountNet), 0)).toFixed(2),
    };
}

export const updateInvoiceItem = (invoice: Invoice, updateItemData: {
    whichItem: number;
    fieldToUpdate: keyof InvoiceItem;
    newValue: unknown
}): Invoice => {

    const updatedItems = invoice.items.map((item, index) => {
        if (index === updateItemData.whichItem) {
            const updatedItem = recalculateItem({
                ...item,
                [updateItemData.fieldToUpdate]: updateItemData.newValue
            });
            return updatedItem;
        }
        return item;
    });

    return {
        ...invoice,
        items: updatedItems ?? []
    }
}


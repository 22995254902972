import React, { ChangeEvent, FormEvent, useState } from 'react';
import { SiteNavbar } from '../layout/Navbar/SiteNavbar';
import { HeaderSection } from '../layout/Public/HeaderSection/HeaderSection';
import { SiteFooter } from '../layout/Footer/SiteFooter';
import { Button, Label, Textarea, TextInput } from 'flowbite-react';
import { config } from "../../config/config";

export const ContactUs: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
      });
      const [loading, setLoading] = useState(false);
      const [responseMessage, setResponseMessage] = useState('');
    
      const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
    
        try {
          const response = await fetch(`${config.API_URL}/contact-form/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            setResponseMessage('Dziękujemy za wiadomość. Jak najszybciej odpowiemy mailowo na adres podany w formularzu.');
            setFormData({ name: '', email: '', message: '' }); // Reset form
          } else {
            setResponseMessage('Coś poszło nie tak, spróbuj ponownie.');
          }
        } catch (error) {
          setResponseMessage('Wysyłanie wiadomości nie powiodłowo się. Spróbuj ponownie.');
        }
    
        setLoading(false);
      };




    return (
            <div className="container mx-auto p-4 bg-white">
            
                <HeaderSection heading='Formularz kontaktowy' subheading='Skontaktuj się z nami. Szybko i rzetelnie odpowiemy na Twoje pytania.' />
        
                <form onSubmit={handleSubmit}>
                    <div>
                    <Label htmlFor="name" value="Imię i nazwisko" />
                    <TextInput
                        id="name"
                        name="name"
                        placeholder="Jan Kowalski"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full"
                    />
                    </div>
                    <div className="mt-7 mb-5">
                    <Label htmlFor="email" value="Adres email" />
                    <TextInput
                        id="email"
                        name="email"
                        type="email"
                        placeholder=""
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="w-full"
                    />
                    </div>
                    <div className="mt-7 mb-5">
                    <Label htmlFor="message" value="Treść wiadomości" />
                    <Textarea
                        id="message"
                        name="message"
                        placeholder=""
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows={5}
                        className="w-full"
                    />
                    </div>
                    <div className="w-full py-10 text-center">
                      <Button color="blue" className="font-bold" type="submit" disabled={loading}>
                        {loading ? 'Wysyłanie...' : 'Wysłij'}
                      </Button>
                    </div>
                    {responseMessage && (
                    <p className="text-sm text-gray-700 mt-2">{responseMessage}</p>
                    )}
                </form>
              </div>
    );
};

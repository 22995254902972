import React, { useEffect } from 'react';
import { HeaderSection } from '../layout/Public/HeaderSection/HeaderSection';
import { Subheading } from '../article/Subheading/Subheading';
import { Paragraph } from '../article/Paragraph/Paragraph';

export const Terms: React.FC = () => {
    useEffect(() => {
    return () => {
        document.title = 'Regulamin | Instafakt.pl'; // Cleanup or reset title when component unmounts
      };
    });

    return (
            <div className="container mx-auto p-4 bg-white">
            
                <HeaderSection heading='Regulamin' subheading='Niniejszy regulamin określa zasady korzystnia z serwisu instafakt.pl.' />
        
                <Subheading>I. Postanowienia ogólne</Subheading>
                    <Paragraph>1. Niniejszy regulamin określa zasady i warunki korzystania z serwisu [nazwa platformy], dostępnego pod adresem [adres strony].</Paragraph>
                    <Paragraph>2. Operatorem platformy jest [nazwa firmy], z siedzibą w [adres firmy], NIP [numer NIP], REGON [numer REGON].</Paragraph>
                    <Paragraph>3. Korzystanie z platformy oznacza akceptację niniejszego regulaminu.</Paragraph>
                    <Paragraph>4. Niniejszy Regulamin (oraz załączniki stanowiące integralną część niniejszego Regulaminu), udostępniony jest nieodpłatnie za pośrednictwem Serwisu w formie, która umożliwia jego pobranie, utrwalenie i wydrukowanie.</Paragraph>
                
                <Subheading>II. Definicje</Subheading>
                    <Paragraph>1. Użytkownik – osoba fizyczna lub prawna, która korzysta z funkcji platformy po założeniu konta.</Paragraph>
                    <Paragraph>2. Platforma – system informatyczny umożliwiający generowanie i zarządzanie fakturami oraz innymi dokumentami księgowymi online pod adresem https://instafakt.pl</Paragraph>
                    <Paragraph>3. Cennik - informacja o aktualnej wysokości opłat należnych Usługodawcy dostępna pod adresem https://instafakt.pl/cennik.</Paragraph>
                    <Paragraph>4. Usługodawca – [nazwa firmy], odpowiedzialny za dostarczenie usług w ramach platformy.</Paragraph>
                    <Paragraph>5. Usługa – funkcjonalności dostępne na platformie, takie jak tworzenie faktur i inne.</Paragraph>
                
                <Subheading>III. Warunki korzystania z platformy</Subheading>
                    <Paragraph>1. Korzystanie z funkcjonalności platformy wymaga rejestracji. Rejestracja na platformie jest dobrowolna i bezpłatna, choć niektóre usługi mogą być dostępne wyłącznie w ramach płatnych planów. </Paragraph>
                    <Paragraph>2. Użytkownik ma obowiązek podania prawdziwych danych podczas rejestracji oraz ich aktualizowania w razie zmiany.</Paragraph>
                    <Paragraph>3. Użytkownik zobowiązuje się do korzystania z platformy zgodnie z obowiązującymi przepisami prawa oraz zasadami współżycia społecznego.</Paragraph>
                    <Paragraph>4. Użytkownik nie może przekazywać swoich danych logowania osobom trzecim. W przypadku ich ujawnienia, Użytkownik ponosi pełną odpowiedzialność za działania osób, które uzyskają dostęp do jego konta.</Paragraph>
                    <Paragraph>5. Rejestracja na platformie oznacza akceptację niniejszego regulaminu oraz polityki prywatności pod adres https://instafakt.pl/polityka-prywatnosci.</Paragraph>


                <Subheading>IV. Zakres usług</Subheading>
                    <Paragraph>1. Główną usługą oferowaną na platformie jest tworzenie faktur online.</Paragraph>

                <Subheading>V. Odpowiedzialność usługodawcy</Subheading>
                    <Paragraph>1. Usługodawca dokłada wszelkich starań, aby platforma działała nieprzerwanie i była wolna od błędów, jednak nie ponosi odpowiedzialności za przerwy w dostępie do serwisu spowodowane przyczynami technicznymi, na które nie miał wpływu.</Paragraph>
                    <Paragraph>2. Usługodawca nie ponosi odpowiedzialności za działania Użytkownika, które naruszają prawo, ani za treści generowane przez Użytkowników w ramach korzystania z platformy.</Paragraph>
                    <Paragraph>3. Usługodawca nie ponosi odpowiedzialności za skutki prawne związane z błędami w wystawianych fakturach, wynikającymi z nieprawidłowego wprowadzenia danych przez Użytkownika.</Paragraph>
                
                <Subheading>VI. Ochrona danych osobowych</Subheading>
                    <Paragraph>1. Dane osobowe Użytkowników są przetwarzane zgodnie z obowiązującymi przepisami prawa, w tym z ustawą o ochronie danych osobowych oraz Rozporządzeniem RODO.</Paragraph>
                    <Paragraph>2. Użytkownik ma prawo do dostępu do swoich danych, ich modyfikacji, a także do żądania ich usunięcia z bazy danych platformy. W tym celu należy wysłać wiadomość email na adres komunikacja@instafakt.pl lub skorzystać z formularza kontaktowego na stronie https://instafakt.pl/kontakt.</Paragraph>
                    <Paragraph>3. Usługodawca stosuje odpowiednie środki techniczne i organizacyjne w celu ochrony danych przed nieautoryzowanym dostępem oraz ich utratą.</Paragraph>
                
                <Subheading>VII. Płatności i rozliczenia</Subheading>
                    <Paragraph>1, Korzystanie z podstawowych funkcji platformy jest bezpłatne. Usługi dodatkowe są dostępne po uiszczeniu odpowiednich opłat, zgodnie z Cennikiem.</Paragraph>
                    <Paragraph>2. Płatności za usługi dodatkowe można dokonać za pośrednictwem systemów płatności elektronicznych lub przelewem bankowym.</Paragraph>
                    <Paragraph>3. W przypadku niewywiązania się z zobowiązań finansowych, Usługodawca zastrzega sobie prawo do zawieszenia lub usunięcia konta Użytkownika.</Paragraph>

                <Subheading>VIII. Rozwiązanie umowy</Subheading>
                    <Paragraph>1. Użytkownik ma prawo do rozwiązania umowy w dowolnym momencie poprzez usunięcie konta na platformie. W tym celu należy skorzystać z formularza kontaktowego na stronie https://instafakt.pl/kontakt.</Paragraph>
                    <Paragraph>2. Usługodawca zastrzega sobie prawo do wypowiedzenia umowy i zablokowania konta Użytkownika w przypadku naruszenia regulaminu.</Paragraph>
                    <Paragraph>3. Rozwiązanie umowy nie zwalnia Użytkownika z obowiązku uregulowania zaległych płatności.</Paragraph>
                
                <Subheading>IX. Postanowienia końcowe</Subheading>
                    <Paragraph>1. Regulamin może być zmieniany przez Usługodawcę w każdym czasie. O zmianach Użytkownicy zostaną poinformowani za pośrednictwem wiadomości email na adres powiązany z ich kontem.</Paragraph>
                    <Paragraph>2. W sprawach nieuregulowanych niniejszym regulaminem mają zastosowanie przepisy Kodeksu Cywilnego oraz inne odpowiednie przepisy prawa polskiego.</Paragraph>
                
            </div>
    );
};

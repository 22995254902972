import { useEffect } from 'react';

const useDocumentTitle = (title?: string) => {
    const fallbackTitle = 'Instafakt';
    useEffect(() => {
        document.title = `${title} | Instafakt.pl`;

        return () => {
        document.title = fallbackTitle; // Cleanup: reset title when component unmounts
        };
    }, [title, fallbackTitle]);
};
export default useDocumentTitle;
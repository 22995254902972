import Cookies from "js-cookie";
import { config } from "../../../../config/config";
import { amountFromCents, stripTrailingZeros } from "../../../../utils/Numbers.util";
import { Invoice } from "../../Invoice.types";

export const sendInvoiceByEMail = async (emailTo: string, invoiceHtml: string, invoiceId: string) => {
  try {
    

      const payload = {
        emailTo,
        invoiceHtml,
      };


    // Send the Blob to the backend
    // const formData = new FormData();
    // formData.append('emailTo', emailTo);
    // formData.append('attachment', invoiceHtml, invoiceId ? `faktura_${invoiceId}.pdf` : `faktura_instafakt_${Date.now()}.pdf`);
   
   
    const response = await fetch(`${config.API_URL}/invoices/${invoiceId}/send-by-email`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${Cookies.get('userToken')}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (response.ok) {
      const responseJson = await response.json();
      const invoicesList = responseJson.map((invoice: Invoice) => adaptInvoiceAPIResponse(invoice));
      return invoicesList;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
  } catch (error: unknown) {
    // Handle errors
    return (error as Error).message || 'Wystąpił błąd.';
  }
};


export const adaptInvoiceAPIResponse = (invoiceInResponse: any): Invoice => {
  return {
    ...invoiceInResponse,
    from: {
      line1: invoiceInResponse.seller_line_1 || '',
      line2: invoiceInResponse.seller_line_2 || '',
      line3: invoiceInResponse.seller_line_3 || '',
    },
    to: {
      line1: invoiceInResponse.buyer_line_1 || '',
      line2: invoiceInResponse.buyer_line_2 || '',
      line3: invoiceInResponse.buyer_line_3 || '',
    },
    dueDate: invoiceInResponse.dueAt || null,
    totalGross: amountFromCents(invoiceInResponse.totalGross),
    totalNet: amountFromCents(invoiceInResponse.totalNet),
    totalTax: amountFromCents(invoiceInResponse.totalTax),
    items: [...(invoiceInResponse.Items ?? []).map((item: any) => {
      return {
        ...item,
        price: amountFromCents(item.price),
        quantity: stripTrailingZeros(item.quantity),
        amountNet: amountFromCents(item.amountNet),
        amountGross: amountFromCents(item.amountGross),
        taxRate: amountFromCents(item.taxRate),
        taxAmount: amountFromCents(item.taxAmount),
      }
    })]
  }
};
import './Invoice.styles.css';
import { useEffect, useState } from 'react';
import { Invoice, InvoiceItem } from './Invoice.types';
import { RegisterModal } from '../auth/RegisterModal/RegisterModal';
import { Button, Datepicker, Label, Select, TextInput, ToggleSwitch, Tooltip } from 'flowbite-react';
import { addInvoice } from '../invoice/invoiceSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { recalculateInvoice, updateInvoiceItem } from './invoice.utils';
import { isSame } from '../../utils/Common.util';
import { createInvoiceRequest } from './services/createInvoiceService/createInvoice.service';
import { addDaysToDate, todayDate } from '../../utils/Date.util';
import ErrorMessage from '../common/ErrorMessage';
import { handleApiResponseError } from '../../utils/Api.util';
import { addThousandsSeparator } from '../../utils/Numbers.util';

export const InvoiceForm = () => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(state => state.user);

	const defaultDate = todayDate();

	// prepare default invoice number
	const [year, month] = defaultDate.split('-');
	const defaultInvoiceNumber = `A1/${month}/${year}`;
	const defaultInvoice = {
		number: defaultInvoiceNumber,
		issuedAt: defaultDate,
		deliveredAt: defaultDate,
		dueDate: addDaysToDate(defaultDate, 30), //default is 30 days ahead
		dueDateDays: 30,
		totalNet: '0',
		totalGross: '0',
		totalTax: '0',
		from: {
			//@ts-ignore
			line1: user?.lastInvoice?.seller_line_1,
			//@ts-ignore
			line2: user?.lastInvoice?.seller_line_2,
			//@ts-ignore
			line3: user?.lastInvoice?.seller_line_3
		},
		to: {
			//@ts-ignore
			line1: user?.lastInvoice?.buyer_line_1,
			//@ts-ignore
			line2: user?.lastInvoice?.buyer_line_2,
			//@ts-ignore
			line3: user?.lastInvoice?.buyer_line_3
		},
		items: []
	}

	const [invoiceData, setInvoiceData] = useState<Invoice>(defaultInvoice);
	const [isNewBuyer, setIsNewBuyer] = useState<boolean>(false);
	

	const [registerModalState, setRegisterModalState] = useState<boolean>(false);
	const [anyTaxRateFieldVisible, setAnyTaxRateFieldVisible] = useState(false);
	const [dueDateVisibility, setDueDateVisibility] = useState(false);

    const [generalError, setGeneralError] = useState<string | null>(null); // For general error handling


	useEffect(() => {
		setIsNewBuyer(!isSame(invoiceData.to, defaultInvoice.to));
		const invoiceRecalculated = recalculateInvoice(invoiceData);
		dispatch(addInvoice(invoiceRecalculated));
		if (!isSame(invoiceData, invoiceRecalculated)) {
			setInvoiceData(invoiceRecalculated);
		}
	}, [invoiceData]);

	const addItem = () => {
		const initialItem: InvoiceItem = {
			description: '',
			quantity: '',
			type: "product",
			price: '0',
			amountNet: '0', // usually the same as price
			amountGross: '0',
			taxRate: '0',
			taxAmount: '0',
		};

		setInvoiceData({
			...invoiceData,
			items: [...invoiceData.items, initialItem],
		});
	};

	const handleItemChange = (itemIndex: number, itemField: keyof InvoiceItem, itemValue: unknown) => {

		const invoiceWithUpdatedItem = updateInvoiceItem(invoiceData, {
			whichItem: itemIndex,
			fieldToUpdate: itemField,
			newValue: itemValue
		});
		setInvoiceData(invoiceWithUpdatedItem);
	};

	const handleSaveButton = async () => {
		setGeneralError('');
		if (!user.token) {
			setRegisterModalState(true);
			return;
		}

		await createInvoiceRequest({ invoice: invoiceData, token: user.token, metaData: {isNewBuyer} }).catch(
			(error: unknown) => {
				const errorMessage = handleApiResponseError(error);
				setGeneralError(errorMessage);
			}
		);
	}

	const setUpdatedItem = (updatedItem: InvoiceItem, index: number) => { 
		setInvoiceData({
			...invoiceData, 
			items: [
				...invoiceData.items.map((i, idx) => {
					if (idx === index) { 
						return updatedItem; 
					} else {
						return i;
					}
				})
			]
		})
	};
	

	const removeInvoiceItem = (index: number) => {
		const invoiceWithRemovedItem = {
			...invoiceData,
			items: invoiceData.items.filter((item, itemIndex) => itemIndex !== index)
		}
		setInvoiceData(invoiceWithRemovedItem);
	}
	const cloneInvoiceItem = (index: number) => {
		const invoiceWithRemovedItem = {
			...invoiceData,
			items: [
				...invoiceData.items,
				invoiceData.items[index]
			]
		}
		setInvoiceData(invoiceWithRemovedItem);
	}



	return (
		<>

		<div className="container mx-auto p-4 bg-white">
			{/* <SiteNavbar /> */}

			{/* <div className="border-t-8 border-gray-700 h-2"></div> */}

			<div className="container mx-auto py-6 px-4">
				{/* top menu icons */}
				<div className="flex justify-between">
					<h2 className="text-2xl font-bold mb-6 pb-2 tracking-wider uppercase">Faktura</h2>
					<div>
						<div className="relative mr-4 inline-block">

							<div className="text-gray-500 cursor-pointer w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center"
								onClick={() => setRegisterModalState(true)}
							>
								<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-printer" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
									<rect x="0" y="0" width="24" height="24" stroke="none"></rect>
									<path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />
									<path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />
									<rect x="7" y="13" width="10" height="8" rx="2" />
								</svg>
							</div>

						</div>

						<div className="relative inline-block">
							<div
								data-modal-toggle="registerModal"
								data-tooltip-target="tooltip-reload"
								className="text-gray-500 cursor-pointer w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center"
								onClick={() => setRegisterModalState(true)}

							>
								<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
									<rect x="0" y="0" width="24" height="24" stroke="none"></rect>
									<path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -5v5h5" />
									<path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 5v-5h-5" />
								</svg>
								<span>Drukuj</span>
							</div>
						</div>
					</div>
				</div>

				<ErrorMessage message={generalError} />

				{/* dates */}
				<div className="flex mb-8 justify-between">
					<div className="w-2/4">
						<div className="mb-2 md:mb-1 md:flex items-center">
							<label className="w-30 text-gray-800 block font-bold text-sm uppercase tracking-wide">Numer faktury</label>
							<span className="mr-2 inline-block md:block">:</span>
							<TextInput
								placeholder="A1/1/2024"
								required
								color="blue"
								value={invoiceData.number}
								onChange={(e) => setInvoiceData({ ...invoiceData, number: e.target.value })}
							/>
						</div>
					</div>
					<div className="w-2/4 text-right">
						<div className="mb-2 md:mb-1 md:flex items-center">
							<label className="w-3/4 text-gray-800 block font-bold text-sm uppercase tracking-wide">Data wystawienia</label>
							<span className="mr-2 inline-block md:block">:</span>
							<div className="flex-1">
								<Datepicker language="pl-PL" />
							</div>
						</div>

						<div className="mb-2 md:mb-1 md:flex items-center">
							<label className="w-3/4 text-gray-800 block font-bold text-sm uppercase tracking-wide">Data wykonania / sprzedaży</label>
							<span className="mr-2 inline-block hidden md:block">:</span>
							<div className="flex-1">
								<Datepicker language="pl-PL" />
							</div>
						</div>
					</div>
				</div>

				{/* sender, recipient */}
				<div className="flex flex-wrap justify-between mb-8">
					<div className="w-full md:w-1/3 mb-2 md:mb-0">
						<label className="text-gray-800 block mb-1 font-bold text-sm uppercase tracking-wide">Sprzedawca:</label>
						<TextInput
							className='mb-1'
							placeholder="Nazwa Twojej firmy"
							value={invoiceData.from.line1}
							onChange={(e) => setInvoiceData({ ...invoiceData, from: { ...invoiceData.from, line1: e.target.value } })}
						/>
						<TextInput
							className='mb-1'
							placeholder='Polna 23, 04-332 Warszawa'
							value={invoiceData.from.line2}
							onChange={(e) => setInvoiceData({ ...invoiceData, from: { ...invoiceData.from, line2: e.target.value } })}
						/>
						<TextInput
							className='mb-1'
							placeholder='NIP: XXXXXXXX'
							value={invoiceData.from.line3}
							onChange={(e) => setInvoiceData({ ...invoiceData, from: { ...invoiceData.from, line3: e.target.value } })}
						/>
					</div>

					<div className="w-full md:w-1/3">
						<label className="text-gray-800 block mb-1 font-bold text-sm uppercase tracking-wide">Nabywca:</label>
						<TextInput
							className='mb-1'
							placeholder='Nazwa firmy nabywcy'
							value={invoiceData.to.line1}
							onChange={(e) => setInvoiceData({ ...invoiceData, to: { ...invoiceData.to, line1: e.target.value } })}
						/>
						<TextInput
							className='mb-1'
							placeholder='Kwiatowa 23, 00-34 Warszawa'
							value={invoiceData.to.line2}
							onChange={(e) => setInvoiceData({ ...invoiceData, to: { ...invoiceData.to, line2: e.target.value } })}
						/>
						<TextInput
							className='mb-1'
							placeholder='NIP: XXXXXXXX'
							value={invoiceData.to.line3}
							onChange={(e) => setInvoiceData({ ...invoiceData, to: { ...invoiceData.to, line3: e.target.value } })}
						/>
					</div>
				</div>

				{/* items header */}
				<div className="flex -mx-1 border-b py-2 items-start">
					<div className="flex-1 px-1 w-32 ">
						<p className="text-gray-800 uppercase tracking-wide text-sm font-bold">Nazwa</p>
					</div>

					<div className="px-1 w-32">
						<p className="text-gray-800 uppercase tracking-wide text-sm font-bold">Cena netto</p>
					</div>

					<div className="px-1 w-20">
						<p className="leading-none">
							<span className="block uppercase tracking-wide text-sm font-bold text-gray-800">Ilość</span>
						</p>
					</div>

					<div className="px-1 w-32">
						<p className="leading-none">
							<span className="block uppercase tracking-wide text-sm font-bold text-gray-800">Typ</span>
						</p>
					</div>

					<div className="px-1 w-32">
						<p className="leading-none">
							<span className="block uppercase tracking-wide text-sm font-bold text-gray-800">Kwota netto</span>
						</p>
					</div>

					<div className="px-1 w-32">
						<p className="leading-none">
							<span className="block uppercase tracking-wide text-sm font-bold text-gray-800">Stawka VAT</span>
							<span className="font-medium text-xs text-gray-500">(w %)</span>
						</p>
					</div>

					<div className="px-1 w-20">
						<p className="leading-none">
							<span className="block uppercase tracking-wide text-sm font-bold text-gray-800">VAT</span>
						</p>
					</div>

					<div className="px-1 w-32">
						<p className="leading-none">
							<span className="block uppercase tracking-wide text-sm font-bold text-gray-800">Kwota brutto</span>
						</p>
					</div>

					<div className="px-1 w-20 text-right">
					</div>
				</div>

				{/* items */}
				<div>
					{/* Invoice items */}
					{invoiceData.items.map((item: InvoiceItem, index) => (
						<div key={index} className="flex -mx-1 py-2">
							<div className="flex-1 px-1 w-32">
								<TextInput
									placeholder='Opis towaru, usługi'
									value={item.description}
									onChange={(e) => handleItemChange(index, 'description', e.target.value)}
								/>
							</div>

							<div className="px-1 w-32">
								<TextInput
									placeholder='0.00'
									value={item.price}
									onChange={(e) => setUpdatedItem({...item, price: e.target.value}, index)}
									onBlur={() => handleItemChange(index, 'price', item.price)}
								/>
							</div>

							<div className="px-1 w-20">
								<TextInput
									placeholder='0.00'
									value={item.quantity}
									onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
								/>
							</div>

							<div className="px-1 w-32">
								<Select
									value={item.type} onChange={(e) => handleItemChange(index, 'type', e.target.value)}>
									<option value="usługa">usługa</option>
									<option value="produkt">produkt</option>
								</Select>
							</div>

							<div className="px-1 my-auto w-32 text-center">
								<p>{item.amountNet}</p>
							</div>

							<div className="px-1 w-20">
								{(anyTaxRateFieldVisible) ? <TextInput
									placeholder='0.00'
									value={item.taxRate}
									onChange={(e) => setUpdatedItem({...item, taxRate: e.target.value}, index)}
									onBlur={() => handleItemChange(index, 'taxRate', item.taxRate)}

								/>
									:
									<Select
										value={item.taxRate} onChange={(e) => handleItemChange(index, 'taxRate', e.target.value)}>
										<option value="23">23%</option>
										<option value="8">8%</option>
										<option value="7">7%</option>
										<option value="5">5%</option>
										<option value="4">4%</option>
										<option value="0">0%</option>
										<option value="np">NP</option>
										<option value="zw">ZW</option>
										<option value="" onClick={() => setAnyTaxRateFieldVisible(true)}>Inna</option>
									</Select>
								}
							</div>

							<div className="px-1 my-auto w-32 text-center">
								<p>{item.taxAmount}</p>

							</div>

							<div className="px-1 my-auto w-32">
								<p>{item.amountGross} PLN</p>
							</div>

							<div className="px-1 my-auto w-20 text-center inline-flex items-center justify-center">
								<Tooltip content="Duplikuj">
									<div className="mx-2 text-grey-100 cursor-pointer w-6 h-6 rounded-full -100 hover:bg-gray-100 inline-flex items-center justify-center"
										onClick={() => cloneInvoiceItem(index)}
									>
										<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											<path stroke="currentColor" strokeLinejoin="round" strokeWidth="1" d="M9 8v3c0 .6-.4 1-1 1H5m11 4h2c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1h-7a1 1 0 0 0-1 1v1m4 3v10c0 .6-.4 1-1 1H6a1 1 0 0 1-1-1v-7.1c0-.3 0-.5.2-.7l2.5-2.9c.2-.2.5-.3.8-.3H13c.6 0 1 .4 1 1Z" />
										</svg>
									</div>
								</Tooltip>
								<Tooltip content="Usuń">
									<div className="text-red-500 hover:text-red-600 cursor-pointer w-6 h-6 rounded-full hover:bg-gray-100 inline-flex items-center justify-center"
										onClick={() => removeInvoiceItem(index)}
									>
										<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 18 6m0 12L6 6" />
										</svg>
									</div>
								</Tooltip>

							</div>
						</div>
					))}
				</div>


				<button
					className="mt-6 bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 text-sm border border-gray-300 rounded shadow-sm"
					onClick={addItem}
				>
					+ Dodaj pozycję na fakturze
				</button>

				<div className="py-2 ml-auto mt-5 w-full sm:w-2/4 lg:w-1/4">
					<div className="flex justify-between mb-3">
						<div className="text-gray-800 text-right flex-1">Suma netto</div>
						<div className="text-right w-40">
							<div className="text-gray-800 font-medium">
								{addThousandsSeparator(invoiceData.totalNet)}
							</div>
						</div>
					</div>
					<div className="flex justify-between mb-4">
						<div className="text-sm text-gray-600 text-right flex-1">Suma VAT</div>
						<div className="text-right w-40">
							<div className="text-sm text-gray-600">
								{addThousandsSeparator(invoiceData.totalTax)}
							</div>
						</div>
					</div>

					<div className="py-2 border-t border-b">
						<div className="flex justify-between">
							<div className="text-xl text-gray-600 text-right flex-1">Razem do zapłaty</div>
							<div className="text-right w-40">
								<div className="text-xl text-gray-800 font-bold">
									{addThousandsSeparator(invoiceData.totalGross)} PLN
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <!-- Options at the bottom  --> */}

				<div className="flex max-w-md flex-col gap-4">
					<ToggleSwitch checked={dueDateVisibility} label="Dodaj termin zapłaty" onChange={() => setDueDateVisibility(!dueDateVisibility)} />
					{dueDateVisibility ?
						<div>
							<div className="mb-2 block">
								<Label htmlFor="dueDate" value="Termin zapłaty" />
							</div>
							<Select
								value={invoiceData.dueDateDays}
								onChange={(e) => setInvoiceData({ ...invoiceData, dueDateDays: Number(e.target.value) })}
							>
								<option value="30">30 dni</option>
								<option value="14">14 dni</option>
								<option value="7">7 dni</option>
							</Select>
						</div>
						: ''}
				</div>

				<div className="w-full py-10 text-center">
					<Button color="blue" className="font-bold" onClick={() => handleSaveButton()}>Zapisz fakturę</Button>
				</div>

				<RegisterModal show={registerModalState} onClose={() => setRegisterModalState(false)} />

			</div>
		</div >
		</>
	)
}


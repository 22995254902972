import { Route, Routes, useNavigate } from "react-router-dom"
import { DashboardNavbar } from "./Navbar/DashboardNavbar"
import { DashboardSidebar } from "./Sidebar/DashboardSidebar"
import { DashboardInvoiceList } from "./InvoiceList/InvoiceList"
import { DashboardHome } from "./Home/DashboardHome"
import { useAppDispatch, useAppSelector } from "../../store"
import { User } from "../auth/services/RegisterService/Register.types"
import { useEffect } from "react"
import { setUser } from "../auth/UserSlice"
import { getLoggedInUserData } from "../auth/services/getLoggedUserData/getLoggedInUserData.service"
import { DashboardInvoice } from "./Invoice/DashboardInvoice"
import { DashboardInvoiceView } from "./Invoice/DashboardInvoiceView"
import { SiteFooter } from "../layout/Footer/SiteFooter"



export const Dashboard = () => {

    const user = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const validatateUserAccess = async (user?: User) => {
        // if no user data exists, attempt to get it from API call again (uses auth token from cookies)
        if (!user?.email || !user?.token) {
            const refreshedUserInfo = await getLoggedInUserData();
            
            if (!refreshedUserInfo.email) {
                navigate('/logowanie');
                return;
            }
            dispatch(setUser(refreshedUserInfo))
        }
    }

    useEffect(() => {
        validatateUserAccess(user);
    }, []);

    return (

        <section className="bg-white p-3 sm:p-5" >
            <DashboardNavbar />

            <aside className="flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700">
                <div className="flex items-start ">
                    <DashboardSidebar />
                </div>
            </aside>

            

            <main className="relative h-full w-full overflow-y-auto bg-gray-50 dark:bg-gray-900 lg:ml-64">
                <div className="px-4 pt-6 py-6">
                    <Routes>
                        <Route index element={<DashboardHome />} />
                        <Route path="invoices" element={<DashboardInvoiceList />} />
                        <Route path="invoices/create" element={<DashboardInvoice />} />
                        <Route path="invoices/:id" element={<DashboardInvoiceView />} />
                    </Routes>
                </div>
            </main>
            <hr className="h-px my-16 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <SiteFooter />
        </section>

    )
}
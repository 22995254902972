import { useState } from 'react';
import { Tabs, Label, TextInput, Card, Button, Checkbox, Alert } from 'flowbite-react';
import useDocumentTitle from '../../utils/customHooks/usePageTitle';
import { Link } from 'react-router-dom';
import ErrorMessage from '../common/ErrorMessage';
import { payForOrderRequest } from './Order.service';
import { OrderSummaryForm } from './Order.types';
import { useAppSelector } from '../../store';



export const OrderSummary = () => {
  const [activeTab, setActiveTab] = useState('company');
  const [formFieldErrorTerms, setFormFieldErrorTerms] = useState('');

  const [formData, setFormData] = useState<OrderSummaryForm>({
    name: '',
    surname: '',
    address_street: '',
    address_postcode: '',
    address_city: '',
    taxNumber: '',
  });

    const user = useAppSelector(state => state.user);


  const [isTermsAgreeChecked, setTermsAgreeChecked] = useState(false);
  const [generalError, setGeneralError] = useState<string | null>(null); // For general error handling

  useDocumentTitle("Zamowienie | Instafakt.pl");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTermsAgreeCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreeChecked(e.target.checked);
    if (e.target.checked) {
      setFormFieldErrorTerms(''); // Hide error if the checkbox is checked
    }
  };

  const handlePayButtonClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    // validate form fields
    let valid = true;

    // if( Object.values(formData).some((field) => !field)) {
    //   setGeneralError('Wypełnij wszystkie pola.');
    //   valid = false;
    // }

      // Checkbox validation
    if (!isTermsAgreeChecked) {
        setFormFieldErrorTerms('Zaznacz pole z akceptacją regulaminu.');
        valid = false;
    }

    if (!valid) {
      return;
    }
    // remove ts ignore when add .subscription to state user type
    // @ts-ignore 
    const paymentPage = await payForOrderRequest({...formData, user_email: user.email , user_subscription_id: user.subscription.id});
    window.location.href = paymentPage || '/404';
    return;
  }

  return (
    <div className="container mx-auto p-4">
      <ErrorMessage message={generalError} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

      {/* Left side: Form Section */}
      <div className="md:col-span-2">
        <Tabs.Group
          aria-label="Order Type"
          style="underline"
          onActiveTabChange={(tabIndex) => setActiveTab(tabIndex === 0 ? 'personal' : 'company')}
        >


          <Tabs.Item 
            title="Firma"
            >
            <form className="space-y-4">
              <div>
                <Label htmlFor="companyName" value="Nazwa firmy" />
                <TextInput
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Nazwa firmy"
                  required
                />
              </div>
              <div>
                <Label htmlFor="taxNumber" value="Numer NIP" />
                <TextInput
                  id="taxNumber"
                  name="taxNumber"
                  value={formData.taxNumber}
                  onChange={handleChange}
                  placeholder="123456789"
                  required
                />
              </div>
              <div>
                <Label htmlFor="address" value="Adres firmy" />
                <TextInput
                  id="address_street"
                  name="address_street"
                  value={formData.address_street}
                  onChange={handleChange}
                  placeholder="Ulica"
                  required
                />
              </div>
              <div>
                <Label htmlFor="address" value="Kod pocztowy" />
                <TextInput
                  id="address_postcode"
                  name="address_postcode"
                  value={formData.address_postcode}
                  onChange={handleChange}
                  placeholder="00-343"
                  required
                />
              </div>
              <div>
                <Label htmlFor="address" value="Miasto" />
                <TextInput
                  id="address_city"
                  name="address_city"
                  value={formData.address_city}
                  onChange={handleChange}
                  placeholder="Miasto"
                  required
                />
              </div>
              
            </form>
          </Tabs.Item>

          <Tabs.Item title="Osoba prywatna">
            <form className="space-y-4">
              <div>
                <Label htmlFor="name" value="Imię" />
                <TextInput
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Jan"
                  required
                />
              </div>
              <div>
                <Label htmlFor="surname" value="Nazwisko" />
                <TextInput
                  id="surname"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange}
                  placeholder="Doe"
                  required
                />
              </div>
              <div>
                <Label htmlFor="address" value="Adres" />
                <TextInput
                  id="address"
                  name="address"
                  value={formData.address_street}
                  onChange={handleChange}
                  placeholder="123 Main St"
                  required
                />
              </div>
            </form>
          </Tabs.Item>
        </Tabs.Group>
      </div>

      {/* Right side: Order Summary Section */}
      <div>
        <Card>
          <h2 className="text-lg font-semibold">Zamowienie</h2>
          <div className="space-y-2">
            <div>
              <span className="font-medium">Plan abonamentu:</span> Premium Plan
            </div>
            <div>
              <span className="font-medium">Okres:</span> 12 mies.
            </div>
            <div>
              <span className="font-medium">Cena:</span> 50.00 zł
            </div>
            <div>
              <span className="font-medium">VAT:</span> 10.00 zł
            </div>
            {/* <div>
              <span className="font-medium">Razem do zapłaty:</span> 60.00 zł
            </div> */}
            <div className="font-bold text-xl">
              Razem: 60 zł
            </div>
          </div>
          <div className="flex gap-2 mt-7 mb-5">
                  <Checkbox id="agree" className="cursor-pointer" onChange={handleTermsAgreeCheckboxChange} />
                  
                  <Label className="flex cursor-pointer" htmlFor="agree" >
                      <p className="text-xs">
                          Akceptuję
                      </p>
                      <Link className="text-blue-600 hover:underline dark:text-blue-500 px-1" to="/regulamin" target='new'>
                          <p className="text-xs"> regulamin i politykę prywatności
                          </p>
                      </Link>
                  </Label>   
                  </div>
                  {formFieldErrorTerms && ( <Alert color="failure" className="mt-2">{ formFieldErrorTerms }</Alert>)}  

          <Button color="blue" fullSized={true} onClick={handlePayButtonClick}>Zamawiam</Button>
        </Card>
      </div>
    </div>
    </div>
  );
};

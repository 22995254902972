import Cookies from "js-cookie";
import { config } from "../../../../config/config";
import { amountFromCents, stripTrailingZeros } from "../../../../utils/Numbers.util";
import { Invoice } from "../../Invoice.types";


export interface UserInvoicesFetchResult {
  invoices: Invoice[];
  pagination: {
    currentPage: string;
    previousPage?: string | null;
    nextPage?: string | null;
    totalItems: string;
    totalPages: string;
    itemsPerPage: string;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    links?: {
      firstPage?: string;
      nextPage?: string;
      lastPage?: string;
      previousPage?: string;
    }
  }
}


export const getInvoicesByUserRequest = async (paginationNumber?: string): Promise<UserInvoicesFetchResult> => {
  try {
    const userId = Cookies.get('userId');
    const userToken = Cookies.get('userToken');
    const filterPagination = paginationNumber ? `/?page=${paginationNumber}` : '';
    const response = await fetch(`${config.API_URL}/users/${userId}/invoices${filterPagination}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      },
    });

    if (response.ok) {
      const responseJson = await response.json();
      const backendInvoices = responseJson.invoices as Invoice[];
      const invoicesList = backendInvoices.map((invoice: Invoice) => adaptInvoiceAPIResponse(invoice));
      return {
        ...responseJson,
        invoices: invoicesList
      }
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
  } catch (error: unknown) {
    // Handle errors
    console.error('Error getting invoices by user:', (error as any).message);
    throw error;
  }
};


export const adaptInvoiceAPIResponse = (invoiceInResponse: any): Invoice => {
  return {
    ...invoiceInResponse,
    from: {
      line1: invoiceInResponse.seller_line_1 || '',
      line2: invoiceInResponse.seller_line_2 || '',
      line3: invoiceInResponse.seller_line_3 || '',
    },
    to: {
      line1: invoiceInResponse.buyer_line_1 || '',
      line2: invoiceInResponse.buyer_line_2 || '',
      line3: invoiceInResponse.buyer_line_3 || '',
    },
    dueDate: invoiceInResponse.dueAt || null,
    totalGross: amountFromCents(invoiceInResponse.totalGross),
    totalNet: amountFromCents(invoiceInResponse.totalNet),
    totalTax: amountFromCents(invoiceInResponse.totalTax),
    items: [...(invoiceInResponse.Items ?? []).map((item: any) => {
      return {
        ...item,
        price: amountFromCents(item.price),
        quantity: stripTrailingZeros(item.quantity),
        amountNet: amountFromCents(item.amountNet),
        amountGross: amountFromCents(item.amountGross),
        taxRate: amountFromCents(item.taxRate),
        taxAmount: amountFromCents(item.taxAmount),
      }
    })]
  }
};
import React from 'react';
import { ListGroup } from 'flowbite-react';
import { SiteNavbar } from '../../layout/Navbar/SiteNavbar';
import { SiteFooter } from '../../layout/Footer/SiteFooter';
import { HeaderSection } from '../../layout/Public/HeaderSection/HeaderSection';

export const ArticleList: React.FC = () => {
    const articles = [
        {
            id: 1,
            title: 'Dlaczego warto tworzyć faktury online',
            url: '/article/faktury-online'
        },
        {
            id: 2,
            title: 'Wzór faktury',
            url: '/article/faktura-wzor'
        }
    ];

    return (
            <div className="container mx-auto p-4">
            
                <HeaderSection heading='Baza Wiedzy' subheading='Artykuły na temat fakturowania i księgowości firmy online' />
                
                <div className="flex justify-center space-x-36 py-8">
                    <ListGroup>
                        {articles.map((article) => (
                            <ListGroup.Item key={article.id}>
                                <a href={article.url} className="text-blue-600 hover:underline">
                                <p className="text-lg">{article.title}</p>
                                </a>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            </div>
    );
};

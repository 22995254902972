import { Outlet } from 'react-router-dom';
import { SiteFooter } from '../Footer/SiteFooter';
import { SiteNavbar } from '../Navbar/SiteNavbar';

export const Website = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <SiteNavbar />
      
      <main className="flex-grow">
        {/* The Outlet component renders the matched route's component */}
        <Outlet />
      </main>
      
      <SiteFooter />
    </div>
  );
};
